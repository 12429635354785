import React from 'react';
import useUser from '../../hooks/useUser';
import Loader from '../../components/loader';
import SubscriptionDetail from './detail';
import './subscription.css';

export default function Subscription ()  {
  const { user, showTrial, loadingUser } = useUser()
  const showRenew = user.days >= 7 && user.has_subscription === 2;

  return (
    <div className="main-content">
      {loadingUser
        ? (
          <Loader
            style={{
              marginTop: 80,
              zIndex: 500,
              backdropFilter: 'blur(12px)'
            }}
          />
        ) : (
          <SubscriptionDetail
            showRenew={showRenew}
            showTrialFinish={showTrial}
          />
        )
      }
    </div>
  );
};
