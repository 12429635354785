import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import Translate from '../../utils/Translate';
import Projects from '../projects/';
import avatarDefault from '../../assests/img/avatar-default.png';
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import socketIOClient from 'socket.io-client';
import Storage from "localStorage";
import './UserMenu.css';

const Menu = ({
  isOpenAvatarMenu,
  onCloseModal,
  user,
  inspiredStore,
  signOut,
  lang,
  showTrial,
  handleLanguageChange,
  handleProfile
}) => {
  const userAvatar = !user.img.includes('https') ? avatarDefault : user.img;

  return (
    <Modal
      open={isOpenAvatarMenu}
      onClose={onCloseModal}
      classNames={{
        overlay: 'styles_overlay__CLSq-2',
        modal: `d-none d-sm-none d-md-none d-lg-block d-xl-block styles_modal__gNwvD3`
      }}
      center
    >
      <section>
        <div>
          <img src={userAvatar} alt='Avatar-signOut' />
          <span className='avatar-desktop'>{user.name}</span>
        </div>
        <div style={{ marginTop: 15 }}>
          <label style={{
            fontSize: 11,
            fontWeight: 'bold',
            color: 'white'
          }}>
            {Translate.get("language", "LANGUAGE")}
          </label>
          <div className="user-menu_wrapper">
            <select
              name="lang"
              className="user-menu_select"
              value={lang || 'en'}
              onChange={(e) => {
                handleLanguageChange(e);
                inspiredStore.setLang();
              }}
            >
              <option value="en">{Translate.get("en", "English")}</option>
              <option value="es">{Translate.get("es", "Spanish")}</option>
            </select>
          </div>
        </div>
        <Projects
          showTrial={showTrial}
          user={user}
          onCloseModal={onCloseModal}
        />
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto' }}>
            <button
              id="register"
              type="submit"
              className="generate-ideas-button"
              onClick={() => {
                onCloseModal();
                handleProfile();
              }}
            >
              {Translate.get('account', 'ACCOUNT')}
            </button>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto' }}>
            <button
              id="register"
              type="submit"
              className="generate-ideas-button"
              onClick={signOut}
            >
              {Translate.get('signOut', 'SIGN OUT')}
            </button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

class UserMenu extends Component {
  state = {
    data: {},
    lang: Storage.getItem('lang') || 'en',
    modeValue: Storage.getItem('mode'),
  }

  componentDidMount() {
    const socket = socketIOClient('https://api.seenapse.it');
    socket.emit('totalNotifications', { user_id: '6678' });

    socket.on('notifications', data => {
      this.setState({ data });
    });
  }

  handleLanguageChange = (event) => {
    const { value } = event.target;
    this.setState({ lang: value });
    Storage.setItem('lang', value);
    Storage.setItem('translate_lang', value);
  }

  handleProfile = () => {
    const { history } = this.props;
    history.push('/update-profile');
  }

  render() {
    const { user, inspiredStore, isOpenAvatarMenu, showTrial, onCloseModal, signOut } = this.props;
    const { lang } = this.state;

    return (
      <Menu
        user={user}
        lang={lang}
        signOut={signOut}
        showTrial={showTrial}
        onCloseModal={onCloseModal}
        inspiredStore={inspiredStore}
        isOpenAvatarMenu={isOpenAvatarMenu}
        handleProfile={this.handleProfile}
        handleLanguageChange={this.handleLanguageChange}
      />
    );
  }
}

export default withRouter(inject("inspiredStore")(observer(UserMenu)));
