import React, { Fragment } from 'react';

import {
  PersonalComponent, SmallTeamsComponent, BigTeamsComponent,
} from '../../components/susbcription';
import '../subscription.css';
import Translate from '../../../utils/Translate';

export default function SubscriptionDetail({ showTrialFinish, showRenew }) {
  return (
    <Fragment>
      {showTrialFinish || showRenew
        ? (
          <div className="subscription px-2 py-6 container">
            <div className="subscription-title">
              <h2 className="text title title-sm">Your free trial has ended</h2>
              <p className="paragraph paragraph-sm">
                {Translate.get('gethundreds', 'But don’t despair! You can choose from one of the plans below and continue right where you left off.')}
              </p>
            </div>
            <div className="subscription-row row gap-4">
              <PersonalComponent amount="24.99 / MONTH" />
              <SmallTeamsComponent amount="499 / MONTH" />
              <BigTeamsComponent />
            </div>
          </div>
        )
        : null
      }
    </Fragment>
  );
}
