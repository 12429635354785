import React, { useEffect,Fragment, Component } from 'react';
import mixpanel from 'mixpanel-browser';
import Loader from '../components/loader';
import MessageLogin from '../shared/alert/MessageLogin';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase/app'
import firebaseConfig from '../config/firebase'
import { intercomConnection } from '../utils/Utils';
import useUser from '../hooks/useUser';
import useNavigation from '../hooks/useNavigation';

firebase.initializeApp(firebaseConfig)

const AppComponent = (props) => {
  const { user, showTrial, loadingUser } = useUser()
  const { onNavigate } = useNavigation()

  const { modalStore } = props;

  useEffect(() => {
    if (user.uid) {
      mixpanel.identify(user.uid);
    }
  }, [user]);

  useEffect(() => {
    if (user.uid) {
      intercomConnection({ name: user.name, email: user.email, userId: user.uid })
      if (showTrial) {
        return  onNavigate('/subscription')
      }
      onNavigate('/ai/project/chat')
    }
  }, [user]);

  return (
    <Fragment>
      {modalStore.showMessageLogin && <MessageLogin />}
      {loadingUser && (
        <Loader
          style={{
            marginTop: 80,
            zIndex: 500,
            backdropFilter: 'blur(12px)'
          }}
        />
      )}
    </Fragment>
  );
};

class App extends Component {
  render() {
    return <AppComponent {...this.props} />
  }
}

export default inject('modalStore')(observer(App));
