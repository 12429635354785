import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import ApiUser from '../../shared/login/ApiUser'
import RegisterSuccess from '../../components/alerts/RegisterSuccess'
import CheckErrors from '../../components/alerts/CheckErrors'
import Storage from 'localStorage'
import avatarDefault from '../../assests/img/avatar-default.png'
import Translate from '../../utils/Translate'
import { decode } from '../../utils/Utils'
import Catalogs from '../../components/HocCatalogs/HocCatalogs'

import './Register.css'

class RegisterSocial extends Component {
  state = {
    name: '',
    profession_id: '',
    bio: '',
    country_id: '',
    email: '',
    session: '',
    avatar: 'avatar_default_v2.png',
    provider: Storage.getItem('provider'),
    showAlertErrors: false,
    showRegisterSuccess: false
  }

  componentDidMount() {
    const { userStore } = this.props
    const token = userStore.tempToken || Storage.getItem('token')
    if (token.length < 1) {
      this.handleRoot()
    }
    const validToken = token.split('.')
    const user_id = Storage.getItem('uid')

    ApiUser.getUser(user_id).then(response => {
      const data = response.data
      this.setState({
        name: data.name,
        avatar: data.img,
        country_id: data.country_id,
        bio: data.bio
      })
    })

    if (user_id === 'null') {
      this.handleUserInfo(validToken)
    }
  }

  handleUserInfo = tempToken => {
    const userInfo = tempToken && decode(tempToken[1])
    this.setState({
      name: userInfo.name,
      email: userInfo.email,
      avatar: userInfo.avatar,
      session: userInfo.session_index || ''
    })
  }

  handleRoot = (route) => {
    window.location = "/"
  }
  componentDidUpdate() {
    const { child } = this.props
    if (child && !this.state.name) {
      const { name, description } = child
      this.setState({
        name,
        description
      })
      this.props.updateForm({ name, description })
    }
  }

  updateField = (field, value) => {
    let errors = []
    if (field === 'email') {
      errors = this.validateEmail(value)
    }

    if (field === 'emailConfirm') {
      const { email, emailConfirm } = this.state
      errors = this.validateEmailConfirm(email, emailConfirm)
    }

    this.setState({
      [field]: value
    })
  }

  closeModalErrors = () => {
    this.setState({ showAlertErrors: false })
  }

  closeModalRegisterSuccess = () => {
    window.location = '/login/email'
  }

  onSumit = async e => {
    e.preventDefault()
    const { userStore } = this.props
    const token = userStore.tempToken

    const errors = this.validateForm(this.state)

    if (errors.length > 0) {
      this.setState({ errors: errors, showAlertErrors: true })
      return
    }
    const params = {
      name: this.state.name,
      profession_id: Number(this.state.profession_id),
      bio: this.state.bio,
      country_id: Number(this.state.country_id),
      avatar: this.state.avatar,
      provider: this.state.provider,
      email: this.state.email,
      language: Storage.getItem('lang'),
      session: this.state.session
    }
    try {
      let {data: data} = await ApiUser.createUserBySocial({ user: params })
      Storage.setItem('token', data.token)
      Storage.setItem('uid', data.id)

      userStore.deleteTempToken()
      this.handleRoot()

      window.gtag('event', 'conversion', {
        'send_to': 'AW-16657396151/PaBlCJb7x8YZELfj7oY-',
        'value': 1.0,
        'currency': 'GBP'
      });

    } catch (error) {
      if (error.response.status === 400) {
        this.setState({
          errors: [`Error email ${error.response.data.errors.email}`],
          showAlertErrors: true
        })
      }
    } finally {
      console.log('ok')
    }
  }

  validateEmail = email => {
    var errors = []
    var validation = new RegExp(
      /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    )
    if (!validation.test(email)) {
      return [
        Translate.get('errEmailValid', 'Please enter a valid email address')
      ]
    }
    return []
  }

  validateEmailConfirm = (email, emailConfirm) => {
    if (email != emailConfirm) {
      return ['Email address not matched']
    }
    return []
  }

  showImage = async img => {
    var files = img.target.files
    for (var i = 0; i < files.length; i++) {
      var file = files[i]
      var imageType = /image.*/
      if (!file.type.match(imageType)) {
        continue
      }

      if (file != undefined) {
        var formData = new FormData()
        formData.append('profilepic', file)

        try {
          const response = await ApiUser.uploadImage(formData)
          this.setState({
            avatar: `https://api.seenapse.it/${response.data.file}`
          })
        } catch (error) {
          this.setState({
            showAlertErrors: true,
            errors: error.response.data.errors
          })
        }
      }
    }
  }

  validateForm = formData => {
    var errorMsg = {
      name: Translate.get('errName', 'Please enter a name'),
      bio: Translate.get('errBio', 'Please enter a bio'),
      profession_id: Translate.get(
        'errProfession',
        'Please enter a profession'
      ),
      country_id: Translate.get('errCountry', 'Please enter a country'),
      avatar: Translate.get('errAvatar', 'Please enter a valid avatar')
    }

    var errors = []
    for (var k in formData) {
      if (typeof formData[k] !== 'function') {
        if (k != 'bio' && k != 'profilepic' && k != 'provider' && k !='session') {
          if (formData[k].length == 0) {
            errors.push(errorMsg[k])
          }
        }
      }
    }

    return errors
  }

  removeImage = () => {
    this.setState({
      avatar: 'avatar_default_v2.png'
    })
  }

  render() {
    const {
      showAlertErrors,
      showRegisterSuccess,
      errors,
      avatar,
      name,
      bio,
      profession,
      country
    } = this.state
    return (
      <div>
        <CheckErrors
          open={showAlertErrors}
          errors={errors}
          onOk={this.closeModalErrors}
        />
        <RegisterSuccess
          open={showRegisterSuccess}
          onOk={this.closeModalRegisterSuccess}
        />
        <div className='header-spacer'></div>
        <div id='profile-preferences' className='login-form'>
          <form>
            <fieldset className='col12 mb-4'>
              <div className='profile-pic'>
                <div className='pic-wrapper'>
                  <div className='pic'>
                    <img
                      id='thumbnail'
                      src={
                        avatar
                          ? avatar
                          : avatarDefault
                      }
                      width='100'
                      height='100'
                    />
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='options'>
                      <label className='file'>
                        <input
                          type='file'
                          onChange={this.showImage}
                          accept="image/png, image/jpeg"
                          name='Upload photo'
                          className='input-file'
                          aria-label='File browser'
                        />
                        <span className='file-title'>
                          {Translate.get('uploadPhoto', 'Upload new image')}
                        </span>
                      </label>
                    </div>
                    {avatar !== 'avatar_default_v2.png' && (
                      <button
                        id='register'
                        className='theme-yellow remove-image'
                        onClick={this.removeImage}
                      >
                        {Translate.get(
                          'removeCurrentImage',
                          'Remove current image'
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </fieldset>
            <div className='row'>
              <fieldset className='col12 col-md-6'>
                <div className='mb-3'>
                  <label className='required'>
                    {Translate.get('name', 'Name')}
                  </label>
                  <input
                    id='name'
                    type='text'
                    value={name}
                    required
                    onChange={e => {
                      this.updateField('name', e.target.value)
                    }}
                  />
                </div>
                <div className='selectors mb-3'>
                  <div>
                    <label className='required'>
                      {Translate.get('yourProfession', 'Your profession')}
                    </label>
                    <div className='select-container'>
                      <Catalogs>
                        {({ professions }) => (
                          <select
                            id='profession_id'
                            name='profession_id'
                            value={profession}
                            onChange={e => {
                              this.updateField('profession_id', e.target.value)
                            }}
                          >
                            <option value='' default selected>
                              {Translate.get(
                                'yourProfession',
                                'Your profession'
                              )}
                            </option>
                            {professions.map(({ id, label }) => (
                              <option key={id} value={id}>
                                {label}
                              </option>
                            ))}
                          </select>
                        )}
                      </Catalogs>
                      <div className='arrow'></div>
                    </div>
                  </div>
                  <div>
                    <label className='required'>
                      {Translate.get(
                        'countryLived',
                        'Country where you have lived most of your life'
                      )}
                    </label>
                    <div className='select-container'>
                      <Catalogs>
                        {({ countries }) => (
                          <select
                            id='country_id'
                            name='country_id'
                            value={country}
                            onChange={e => {
                              this.updateField('country_id', e.target.value)
                            }}
                          >
                            <option className='s' value='' default selected>
                              {Translate.get('country', 'country')}
                            </option>
                            {countries.map(({ id, label }) => (
                              <option key={id} value={id}>
                                {label}
                              </option>
                            ))}
                          </select>
                        )}
                      </Catalogs>
                      <div className='arrow'></div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className='col12 col-md-6'>
                <div className='mb-3'>
                  <label>Bio</label>
                  <textarea
                    id='bio'
                    name='bio'
                    value={bio}
                    onChange={e => {
                      this.updateField('bio', e.target.value)
                    }}
                  ></textarea>
                </div>
                <div id='injection_site'></div>
              </fieldset>
            </div>
          </form>
          <div className='action-form'>
            <div className='left'>
              <span className='asterisk'>*</span>
              <span>
                {Translate.get('fieldsRequired', 'This fields are necessary')}
              </span>
            </div>
            <div className='right'>
              <button
                id='cancel'
                onClick={() => this.handleRoot()
                }
              >
                {Translate.get('cancel', 'Cancel')}
              </button>
              <button
                id='register'
                className='theme-blue'
                onClick={this.onSumit}
              >
                {Translate.get('register', 'Register')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject(
  'modalStore',
  'userStore'
)(observer(withRouter(RegisterSocial)))
