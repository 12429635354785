import React, { Fragment } from 'react'
import { isLoggedIn } from '../utils/Utils';
import { Route, Redirect } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../app/footer';
import { useHistory } from 'react-router-dom';

export default function PublicRoute({
  component: Component,
  restricted,
  ...rest
}) {
  const history = useHistory()
  const hasUser = isLoggedIn()
  const isClimate = history.location.pathname.includes('climate');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasUser && restricted) {
          return <Redirect to='/app' />
        }
        return (
          <Fragment>
            <Header
              history={props.history}
              user={{
                has_subscription: '',
                days: ''
              }}
            />
            <Component {...props} />
            {!isClimate && <Footer />}
          </Fragment>
        )
      }}
    />
  )
}
