import React from 'react';
import mixpanel from 'mixpanel-browser';
import { BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import { createRoot } from 'react-dom/client';

import './index.css';
import 'aos/dist/aos.css';

import Routes from './router';

const container = document.getElementById('root');

mixpanel.init("6005a050026c4fd4d872681c3aa77b8e");

AOS.init();

createRoot(container).render(<BrowserRouter>
  <Routes />
</BrowserRouter>,)
